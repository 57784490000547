<template>
  <background :src="bg"/>

  <div class="home-wrapper">
    <div class="home">
      <div class="p-field p-fluid">
        <InputText id="username" v-model="keyword" type="username" aria-describedby="username-help"
                   placeholder="Кодовое слово" ref="username"/>
      </div>
    </div>
  </div>
</template>

<script>
import Bg from '@/components/Bg'
import InputText from 'primevue/inputtext'

export default {
  name: 'Home',
  data: function () {
    return {
      keyword: '',
      bg: 'url(' + require('@/assets/philipp-deus-3c4JB8gaTM8-unsplash.jpg') + ')',
      // bg: 'url(' + require('@/assets/mary-ray-cYvsggwKvWA-unsplash.jpg') + ')',
    }
  },
  components: {
    'background': Bg,
    InputText
  },
  watch: {
    'keyword': function (value) {
      const guest = this.$store.getters.guest(value)

      if (guest !== undefined) {
        this.$router.push({name: 'Invite', params: {guestId: value}})
      }
    }
  }
}
</script>

<style scoped>
.home-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.home {
  color: #f5f5f5;
  margin: auto auto;
  max-width: 25%;
}
</style>
