import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';

import store from './store/index.js';

import 'primevue/resources/themes/saga-blue/theme.css'       // theme
import 'primevue/resources/primevue.min.css'                 // core css
import 'primeicons/primeicons.css'                           // icons

const app = createApp(App)

app.use(store)
app.use(router)
app.use(PrimeVue, {
    locale: {
        accept: 'Да',
        reject: 'Нет',
        //...
    }
});

app.use(ConfirmationService)
app.directive('tooltip', Tooltip);

app.mount('#app')
