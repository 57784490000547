<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
import firebase from 'firebase/app';

export default {
  created() {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional

    var firebaseConfig = {
      apiKey: "AIzaSyDfHvJhVP3J_KTNqavmVa84AxYAJf7fWPg",
      authDomain: "may-87bf6.firebaseapp.com",
      databaseURL: "https://may-87bf6-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "may-87bf6",
      storageBucket: "may-87bf6.appspot.com",
      messagingSenderId: "822191891420",
      appId: "1:822191891420:web:0458e11c9f93cdd9e52519",
      measurementId: "G-76QGSW2NRQ"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  }
}
</script>
