/* eslint-disable */

import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Invite from "@/views/Invite";
import store from '../store/index.js'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/:guestId',
        name: 'Invite',
        component: Invite
        // component: Home
    },
    // {
    //     path: '/invite',
    //     name: 'Invite',
    //     component: Invite
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name === 'Invite') {
        const guest = store.getters.guest(to.params.guestId)

        if (guest) {
            to.params['guest'] = guest
            next()
        } else {
            next({name: 'Home'})
        }
    } else {
        next()
    }
})

export default router
