/* eslint-disable */

import {createStore} from "vuex";

const store = createStore({
    state: {
        guests: [
            {
                'id': 'mama',
                'desc': 'Родители Тани',
                'title': 'Дорогие Светлана Николевна и Игорь Юрьевич!',
                'plural': true,
            },
            {
                'id': 'ksks',
                'desc': 'Семья брата Тани',
                'title': 'Дорогие Сергей, Катя и Соня!',
                'plural': true,
            },
            {
                'id': 'mvml',
                'desc': 'Масальские',
                'title': 'Дорогие Василий, Мария и Лука!',
                'plural': true,
            },
            {
                'id': 'evvl',
                'desc': 'Женя и Влад',
                'title': 'Дорогие Женя и Влад!',
                'plural': true,
            },
            {
                'id': 'skmi',
                'desc': 'Мякотины',
                'title': 'Дорогие Илья и Ксюша!',
                'plural': true,
            },
            {
                'id': 'drdn',
                'desc': 'Дьяконовы',
                'title': 'Дорогие Роман и Наталья!',
                'plural': true,
            },
            {
                'id': 'xaxa',
                'desc': 'Горячев / Степановы',
                'title': 'Дорогие Евгений, Алина и Тася!',
                'plural': true,
            },
            {
                'id': 'vmbe',
                'desc': 'Варя и Эрик',
                'title': 'Дорогие Варя и Эрик!',
                'plural': true,
            },
            {
                'id': 'mike',
                'desc': 'Мишган',
                'title': 'Михаил, амиго!',
                'plural': false,
            },
            {
                'id': 'ilya',
                'desc': 'фотограф',
                'title': 'Дорой Илья!',
                'plural': false,
            },
            {
                'id': 'invs',
                'desc': 'Исычки',
                'title': 'Уважаемые Наталья, Валентина и София!',
                'plural': true,
            },
            // {
            //     'id': '9012',
            //     'desc': 'Кобловы',
            //     'title': 'Уважаемые Наталья и Юрий Юрьевич!',
            //     'plural': false,
            // },
            {
                'id': 'lbks',
                'desc': 'Комсомольск',
                'title': 'Дорогие Лена, Ксюша и Лера!',
                'plural': true,
            },
        ]
    },
    mutations: {},
    actions: {},
    getters: {
        guest: state => id => {
            return state.guests.find(function (element, index) {
                return element.id === id
            })
        }
    },
})

export default store;
