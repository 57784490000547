<template>
  <div :style="getStyle">
    <linear-bg/>
  </div>

</template>

<script>
import LinearBg from "@/components/LinearBg";

export default {
  name: "Bg",
  props: ['src'],
  computed: {
    getStyle: function () {
      return {backgroundImage: this.src}
    }
  },
  components: {
    'linear-bg': LinearBg
  }
}
</script>

<style scoped>
div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background-size: cover;
  background-position: center center;
}
</style>
