<template>
  <background :src="random_bg"/>

  <div class="wrapper">
    <div class="inner">
      <h1>{{ guest ? guest.title : '' }}</h1>

      <h3>Приглашаем на нашу свадьбу!</h3>
      <p>Мероприятие состоится <strong>30 мая</strong> в арт-деревне "Витланд"
        <!--        <span
                  v-tooltip.top="'Где это?'"
                  @click="display = !display"
                  style="cursor: pointer; text-decoration: underline; text-decoration-style: dotted">"Витланд"</span>-->
      </p>
      <p>Сбор гостей с <strong>14:00</strong>, начало в <strong>15:00</strong></p>

      <Button :label="acceptButtonLabel" @click="accept()" class="p-button-raised p-button-rounded"
              :disabled="accepted"/>
      <br/>
      <Button :label="declineButtonLabel" @click="decline()" class="p-button-text" v-if="accepted === undefined"/>

      <div v-if="accepted === true">
        <h2>
          Замечательно!
        </h2>

        <template v-if="guest && guest.id != 'ilya'">
          <h3>
            У нас есть пара вопросов:
          </h3>

          <p>
            <span v-if="plural">Вы приедете сами</span><span v-else>Приедешь самостоятельно</span> или понадобится
            трансфер?
          </p>
          <SelectButton v-model="transport" :options="transportList" optionLabel="name"/>

          <p>
            <span v-if="plural">Останетесь ли и вы с ночёвкой?</span>
            <span v-else>Останешься с ночёвкой?</span>
          </p>
          <SelectButton v-model="sleep" :options="sleepList" optionLabel="name"/>
        </template>

        <h2 v-if="transport && sleep">
          Спасибо за ответ!<br/>Встретимся на свадьбе!
        </h2>
      </div>

      <div v-if="accepted === false">
        <h2>Как жаль :(</h2>
        <h3>Еще есть время и возможность передумать!</h3>
      </div>
    </div>
  </div>

  <ConfirmDialog></ConfirmDialog>

  <Dialog header="Вот где это!" v-model:visible="display">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2303.9639871955414!2d19.943245316117036!3d54.72784828029171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e32d5fbec9901b%3A0x12801cba9b4ce030!2z0JDRgNGCLdC00LXRgNC10LLQvdGPINCS0LjRgtC70LDQvdC0!5e0!3m2!1sru!2sru!4v1619787483716!5m2!1sru!2sru"
        width="80%" height="80%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Bg from "@/components/Bg";
import firebase from 'firebase/app';
import 'firebase/database'

export default {
  data: function () {
    return {
      guest: undefined,
      bgs: [
        'url(' + require('@/assets/bg/bg1.jpeg') + ')',
        'url(' + require('@/assets/bg/bg2.jpeg') + ')',
        'url(' + require('@/assets/bg/bg3.jpeg') + ')',
        'url(' + require('@/assets/bg/bg4.jpeg') + ')',
      ],
      random_bg: undefined,
      display: false,
      accepted: undefined,
      transport: undefined,
      sleep: undefined,
      transportList: [],
      sleepList: [
        {'name': 'Конечно с ночевкой!', 'code': true},
        {'name': 'Уеду :(', 'code': false},
      ]
    }
  },
  name: "Invite",
  methods: {
    accept: async function () {
      this.accepted = true

      const data = {
        id: this.guest.id,
        guest: this.guest,
        accepted: true,
        transport: '',
        sleep: '',
      }

      const response = await firebase.database().ref('guests').push(data)
      this.guest.key = response.key
    },
    decline: function () {
      this.$confirm.require({
        message: this.plural ? 'Вы уверены, что отказываетесь от приглашения?' : 'Ты уверен, что отказываешься от приглашения?',
        header: 'Ты уверен?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.accepted = false
        },
        reject: () => {
          this.accepted = undefined
        }
      });
    },
    getRandomBg: function () {
      return this.bgs[Math.floor(Math.random() * this.bgs.length)]
    },
    find: async function (current_guest) {
      const guest_list = await firebase.database().ref('guests').once("value")
      const guests = guest_list.val()

      if (guests) {
        for (const [key, value] of Object.entries(guests)) {
          if (value.id === current_guest.id) {
            this.guest.key = key
            this.accepted = value.accepted
            this.transport = value.transport
            this.sleep = value.sleep
          }
        }
      }
    }
  },
  mounted: async function () {
    this.guest = this.$route.params['guest']
    await this.find(this.guest)
    this.random_bg = this.getRandomBg()

    console.log(this.plural);

    this.transportList = [
      {'name': this.plural ? 'Сами доедем' : 'Сам доеду', 'code': 'self'},
      {'name': this.plural ? 'Нас надо привезти' : 'Меня надо привезти', 'code': 'transfer'},
    ]
  },
  computed: {
    plural: function () {
      return (this.guest && this.guest.plural);
    },
    acceptButtonLabel: function () {
      return this.accepted ? 'Приглашение принято!' : (this.plural ? 'Мы принимаем приглашение!' : 'Я принимаю приглашение!');
    },
    declineButtonLabel: function () {
      return this.plural ? 'У нас не получится прийти' : 'У меня не получится прийти'
    }
  },
  watch: {
    'sleep': function (value) {
      firebase.database().ref('guests').child(this.guest.key).update({
        'sleep': value
      })
    },
    'transport': function (value) {
      firebase.database().ref('guests').child(this.guest.key).update({
        'transport': value
      })
    }
  },
  components: {
    Button,
    SelectButton,
    Dialog,
    ConfirmDialog,
    'background': Bg
  }
}
</script>

<style scoped>
h1, h2, h3, h4, h5, p, div {
  color: #f5f5f5;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.inner {
}


</style>
